import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, Container, Link, PaletteMode, Stack, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LandingPageAppBar from '../../pages/LandingPageAppBar';
import getLPTheme from '../../getLPTheme';
import { useCookies } from 'react-cookie';
import UserData from '../common/models/UserData';
import { showcase } from './ShowcaseData';
import { APP_IMAGE_URL, APP_NAME, FEATURED_VIEW_URL, UI_BASE_URL } from '../../constants/CommonConstants';
import { analyticsEvent, analyticsLog } from '../../utils/CommonApiCalls';
import { ANA_FEATURED_REPORT_OPENED, ANA_FEATURED_REPORTS_LIST_LOADED } from '../../constants/AnalyticsConstants';
import { ArrowDropDownCircleOutlined, ArrowRightAlt, LoginOutlined, NavigateNextTwoTone } from '@mui/icons-material';
import SEOBasic from '../../utils/SEOBasic';
import ReportFontStyle from '../common/style/ReportFontStyle';
import { getPreferredScheme } from '../../utils/CommonFunctions';

export default function FeaturedListView() {
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [cookies, setCookie] = useCookies(['user']);
  const [user, setUser] = React.useState<UserData>({
    name: '',
    email: '',
    preferences: {
      darkMode: mode === 'dark'
    },
  });

  const toggleColorMode = () => {
    handleSaveUser({ ...user, preferences: { ...user.preferences, darkMode: mode === 'light' } });
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
      setUser(retrievedUser);
    }
  };

  React.useEffect(() => {
    analyticsEvent(ANA_FEATURED_REPORTS_LIST_LOADED);
    handleLoadUser();
  }, []); // Load user data on component mount

  const handleView = (slug: string) => {
    analyticsLog(ANA_FEATURED_REPORT_OPENED, slug);
    //window.location.href=(UI_BASE_URL+FEATURED_VIEW_URL+"?title="+slug);
  };

  const handleSaveUser = (user: UserData) => {
    setCookie('user', JSON.stringify(user), {
      path: '/',
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Expires in 15 day
      secure: true, // Only sent over HTTPS for enhanced security (recommended)
      sameSite: 'strict', // Mitigates CSRF attacks (recommended)
    });
  };
  
  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <SEOBasic title={"Featured Reports"} description={"Discover how our solutions can address your specific needs."} imageUrl={APP_IMAGE_URL}/>
      <LandingPageAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: '10%' ,
          pb: '2%',
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
        <Typography
            variant='caption'
            textAlign="left"
            color="text.primary"
            sx={{ alignSelf: 'left', width: { fontSize:22, marginBottom: '20px' } }}
          >
            Explore following example reports to discover how our solutions can address your specific needs.
          </Typography>
          
        {showcase.map((row) => (
          <Link sx={{textDecoration: "none"}} href={(UI_BASE_URL+FEATURED_VIEW_URL+"?title="+row.slug)}>
            <Card sx={{ minWidth: 275 }} key={row.slug}>
              <CardContent sx={{mx:1, px:2.5}}>
                <Typography variant="subtitle2" color={mode == 'dark' ? "secondary.main": 'primary.main'} sx={{mb: .5, py:0, fontSize: 20 }} component="div">
                {row.title}
                </Typography>
                <Typography sx={{fontSize: 16, mt:0}} variant="body2">
                <ReportFontStyle>{row.snippet.substring(0, 150)}...</ReportFontStyle>
                </Typography>
              </CardContent>
            </Card>
          </Link>
        ))}

            </Stack>
            </Container>
        <Divider />
      </Box>
    </ThemeProvider>
  );
}
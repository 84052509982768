import { API_USER_LOGIN_CHECK, UI_BASE_URL, UI_LOGIN_URL } from "../../../constants/CommonConstants";
import { api } from "../../../utils/Axios";

export async function loginCheck() {
  if (! await isLoggedIn()) {
    window.location.href = UI_LOGIN_URL;
  }
}

export function isLoggedIn() {
  async function checkLogin() {
    const data = await api.get(API_USER_LOGIN_CHECK)
    .then(function (response) {
      if (response.status == 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function (error) {
        return false;
    });
    return (data);
  }
  return checkLogin();
}

export function logout() {
    api.get("/api/user/logout")
    .then(function (response) {
      if (response.status == 200) {
        window.location.href = UI_BASE_URL;
      }
    })
}
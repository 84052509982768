import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Alert, Backdrop, CircularProgress, colors, FormLabel, Grid, IconButton, Link, Pagination, Radio, RadioGroup, Snackbar, TextField, Typography } from '@mui/material';
import { Close, RocketLaunch } from '@mui/icons-material';
import { api } from '../../../utils/Axios';
import { API_LAUNCH_RESEARCH, API_USER_QUOTAS, APP_NAME, FEATURED_URL, SUCCESS_MESSAGE, UI_BASE_URL } from '../../../constants/CommonConstants';
import { API_ERROR } from '../../../constants/CommonMessages';
import UserQuota from '../../common/models/UserQuota';
import { showcase } from '../../otherpages/ShowcaseData';

export default function NewResearchDialog() {
  const [open, setOpen] = React.useState(false);
  const [researchDetails, setResearchDetails] = React.useState('');
  const [researchTitle, setResearchTitle] = React.useState('');
  const [researchLevel, setResearchLevel] = React.useState('detailed');
  const [message, setMessage] = React.useState('');
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [sampleIndex, setSampleIndex] = React.useState(1);
  const [blockedLevelMessage, setBlockedLevelMessage] = React.useState('');
  const [openBackdrop, setBackdrop] = React.useState(false);
  const [quotas, setQuotas] = React.useState<UserQuota>({research_remaining_basic:0,research_remaining_advanced:0,research_remaining_detailed:0});
  const [resultsOutputType, setResultsOutputType] = React.useState('descriptive');

  const handleResultsOutput = (event: SelectChangeEvent) => {
    setResultsOutputType(event.target.value as string);
  };

  function handleBackdropClose () {
    setBackdrop(false);
  }

  function handleBackdropOpen () {
    setBackdrop(true);
  }

  function shouldDisableNewButton () {
    return !(quotas.research_remaining_advanced > 0 || quotas.research_remaining_detailed > 0 || quotas.research_remaining_basic > 0 )
  }

  React.useEffect(() => {
    console.log("showcase: "+sampleIndex);
    // setResearchDetails(showcase[sampleIndex-1].requirement);
  }, [sampleIndex]);

  React.useEffect(() => {
    handleGetQuotas();
  }, []);

  const handleGetQuotas = () => {
    handleBackdropOpen();
    api.get( API_USER_QUOTAS)
    .then(function (response) {
      if (response.status == 200) {
        setQuotas(response.data);        
      }
      handleBackdropClose();
    })
    .catch(function (error) {
      handleBackdropClose();
      console.log("Failed to get quotas")
    });
  };

  const handleLaunchResearch = () => {
    const data = {
      title: researchTitle,
      description: researchDetails,
      level: researchLevel,
      result_type: resultsOutputType
    };
    handleBackdropOpen();
    api.post( API_LAUNCH_RESEARCH, data)
    .then(function (response) {
      if (response.status == 200) {
        if (response.data == SUCCESS_MESSAGE) {
          setMessage("New Research launched, "+APP_NAME+ " will be working hard to delivering best results.")
          setMessageOpen(true)
          setOpen(false)
          setTimeout(function(){
            window.location.reload();
          }, 3000);
        } else {
          setMessage(response.data)
          setMessageOpen(true)            
        }
      } else {
        setMessage(API_ERROR)
        setMessageOpen(true)
      }
      handleBackdropClose();
    })
    .catch(function (error) {
      setMessage(API_ERROR)
      setMessageOpen(true)
      handleBackdropClose();
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleMessageClose = () => {
    setMessageOpen(false);
  };

  const showBlockedMessage = () => {
    setBlockedLevelMessage("Please contact site Admin to enable this option");
  };

  const clearBlockedMessage = () => {
    setBlockedLevelMessage("");
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={messageOpen} onClose={handleMessageClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
        <Alert
          onClose={handleMessageClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Button disabled={shouldDisableNewButton()} variant="contained" color='secondary' onClick={handleClickOpen} endIcon={<RocketLaunch />}>
        New
      </Button>
      
      <Dialog
        PaperProps={{
            sx: {minWidth: '80%'},
        }}
        open={open}
        sx={{maxWidth: '100%', width: '100%' }}
        onClose={handleClose}
      >
        <DialogTitle>
            <Box> Launch New Research </Box>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 'auto',
              width: 'fit-content',
            }}
          >
        <Grid
          item
          sm={12}
          md={7}
          lg={8}
          px={'auto'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 10 },
            gap: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { sm: 'space-between', md: 'flex-end' },
              width: '100%',
            }}
          >
            
            <Grid container spacing={3}>
              <Box width={'60%'}>
                  <FormLabel htmlFor="research-title" required aria-label='left'>
                    Research Title (it will not impact the research)
                  </FormLabel>
                  <TextField
                    size='small'
                    id="research-title"
                    multiline
                    fullWidth={true}
                    defaultValue={researchTitle}
                    onBlur={(event) => setResearchTitle(event.target.value)}
                    rows={1}
                  />
              </Box>
              <Box ml={"10%"} width={'30%'}>
                  {/* <FormLabel htmlFor="research-title" required aria-label='left'>
                    Research report should be
                  </FormLabel>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={resultsOutputType}
                      onChange={handleResultsOutput}
                    >
                      <MenuItem value={"brief"}>Brief</MenuItem>
                      <MenuItem value={"descriptive"}>Descriptive</MenuItem>
                    </Select>
                  </FormControl> */}
              </Box>
              <Box width={'100%'} mt={2}>
                  <FormLabel htmlFor="research-details" aria-label='left'>
                    Please describe your Requirement (refer <Link target="_blank" color={'secondary'} href={UI_BASE_URL+FEATURED_URL}>Featured Reports</Link> to understand how to Give Requirement)
                    {/* Please describe your Requirement (or you can experiment with any of the following samples)  */}
                    {/* <Pagination aria-label='Samples' variant="outlined" count={showcase.length} color="secondary"
                      sx={{ my:1 }} 
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSampleIndex(newValue);
                        }
                      }}
                    /> */}
                  </FormLabel>
                  <TextField
                    size='small'
                    id="research-details"
                    multiline
                    fullWidth={true}
                    value={researchDetails}
                    defaultValue={researchDetails}
                    onChange={(event) => setResearchDetails(event.target.value)}
                    rows={8}
                  />
              </Box>
              <Box my={3} width={'70%'} pt={2.2}>
              <Typography variant='h6'> {"Available Research Quota ("+quotas?.research_remaining_detailed+")"}</Typography>
                {/* <FormLabel id="demo-row-radio-buttons-group-label"><Typography>Please select Research Level. {blockedLevelMessage}</Typography></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="basic" 
                    disabled={quotas?.research_remaining_basic < 1} 
                    onClick={()=> setResearchLevel("basic")} control={<Radio />} 
                    label={"Basic ("+quotas?.research_remaining_basic+")"} />
                  <FormControlLabel value="detailed" 
                   disabled={quotas?.research_remaining_detailed < 1} 
                   onClick={()=> setResearchLevel("detailed")} control={<Radio />} 
                   label={"Detailed ("+quotas?.research_remaining_detailed+")"} />
                  <FormControlLabel 
                    disabled={quotas?.research_remaining_advanced < 1}
                    value="advanced" 
                    onClick={()=> setResearchLevel("advanced")}
                    control={<Radio />} 
                    label={"Advanced ("+quotas?.research_remaining_advanced+")"}
                  />
                  <FormControlLabel
                    value="disabled"
                    disabled
                    control={<Radio />}
                    label="Ultimate (under development)"
                  />
                </RadioGroup> */}
              </Box>
              <Box my={3} width={'30%'} pt={2.2}>
                <Button onClick={handleLaunchResearch} variant="contained" color='secondary' endIcon={<RocketLaunch />} fullWidth>
                  Launch Research
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

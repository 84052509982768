import * as React from 'react';
import { Alert, Backdrop, Button, Card, CardContent, CircularProgress, Container, Drawer, Fab, MenuItem, PaletteMode, Rating, Snackbar, TextField, Typography, Zoom } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from '../../../getLPTheme';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { API_BASE_URL, API_VIEW_RESEARCH, DASHBOARD_URL, UI_BASE_URL } from '../../../constants/CommonConstants';
import { api } from '../../../utils/Axios';
import { loginCheck } from '../user/LoginLogout';
import UserData from '../../common/models/UserData';
import { API_ERROR } from '../../../constants/CommonMessages';
import { AddIcCallOutlined, FeedbackOutlined, QuestionAnswerTwoTone } from '@mui/icons-material';
import FeedbackQuery from '../user/FeedbackQuery';
import { analyticsEvent, analyticsLog } from '../../../utils/CommonApiCalls';
import { ANA_FEEDBACK_QUERY_OPENED, ANA_RESEARCH_REPORT_READ_PER_, ANA_RESEARCH_REPORT_READ_RATING } from '../../../constants/AnalyticsConstants';
import ReportFontStyle from '../../common/style/ReportFontStyle';
import { getPreferredScheme } from '../../../utils/CommonFunctions';

export default function ResearchViewPage() {
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [cookies, setCookie] = useCookies(['user']);
  const query = new URLSearchParams(window.location.search);
  const [data, setData] = React.useState('');
  const [openBackdrop, setBackdrop] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [currentScrollPercentage, setCurrentScrollPercentage] = React.useState(0);
  const [maxScrollPercentageReached, setMaxScrollPercentageReached] = React.useState(0);

  function handleBackdropClose () {
    setBackdrop(false);
  }

  function handleBackdropOpen () {
    setBackdrop(true);
  }

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
    }
  };

  React.useEffect(() => {
    loginCheck();
    handleLoadUser();
    loadResearchReport();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Load user data on component mount

  
  async function handleScroll() {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const currentPosition = window.scrollY;

    var scrollPercentage = (currentPosition / (fullHeight - windowHeight)) * 100;
    scrollPercentage = Math.ceil(scrollPercentage);
    if (scrollPercentage > currentScrollPercentage && scrollPercentage % 10 == 0) {
      await setCurrentScrollPercentage(scrollPercentage);      
    }
  };

  React.useEffect(() => {
    if (currentScrollPercentage > maxScrollPercentageReached) {
      setMaxScrollPercentageReached(currentScrollPercentage);      
    }
  }, [currentScrollPercentage]);

  React.useEffect(() => {
    if (maxScrollPercentageReached > 0) {
      const id = query.get('id')
      if (id) {
        analyticsLog(ANA_RESEARCH_REPORT_READ_PER_ + maxScrollPercentageReached, id)
      } else {
        analyticsEvent(ANA_RESEARCH_REPORT_READ_PER_ + maxScrollPercentageReached)
      }
    }
  }, [maxScrollPercentageReached]);

function loadResearchReport() {
  handleBackdropOpen();
  api.get(API_BASE_URL + API_VIEW_RESEARCH + query)
  .then(function (response) {
    if (response.status == 200) {
      setData(response.data);
    } else {
      setData(API_ERROR);
    }
    handleBackdropClose();
  })
  .catch(function (error) {
    setData(API_ERROR);
    handleBackdropClose();
  });
}


const toggleFeedback = (newOpen: boolean) => () => {
  const id = query.get('id');
  if (newOpen) {
    analyticsLog(ANA_FEEDBACK_QUERY_OPENED, id ? id : "");
  }
  setFeedbackOpen(newOpen);
};

const saveRating = (rating: number) => {
  analyticsLog(ANA_RESEARCH_REPORT_READ_RATING, rating.toString());
};

  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };
  
  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      {/* <LandingPageAppBar mode={mode} toggleColorMode={toggleColorMode} /> */}
      <Box sx={{ bgcolor: 'background.default' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: '5%' ,
          pb: '2%',
        }}
      >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Drawer anchor="right" open={feedbackOpen} onClose={toggleFeedback(false)}>
          <Box
            sx={{
            minWidth: {xs:"80dvw", sm:'32dvw'},
            backgroundColor: 'background.paper',
            flexGrow: 1,
            p: 0
          }}
          >
          <FeedbackQuery noEmail/>
        </Box>
      </Drawer>

      <Zoom in={maxScrollPercentageReached < 50} style={{ transitionDelay: '500ms' }}>
        <Fab onClick={toggleFeedback(true)} variant="extended" 
          sx={{ flex: 1, bottom: { position: 'fixed', bottom: 20, right:20 }}} 
          color="primary" 
          aria-label="feedback">
          <QuestionAnswerTwoTone  sx={{ mr: 1 }} />
          Feedback
        </Fab>
      </Zoom>
      <Zoom in={maxScrollPercentageReached >= 50} style={{ transitionDelay: '500ms' }}>
        <Fab onClick={toggleFeedback(true)} variant="extended" 
          sx={{ flex: 1, bottom: { position: 'fixed', bottom: 20, right:20 }}} 
          color="primary" 
          aria-label="feedback">
          <QuestionAnswerTwoTone  sx={{ mr: 1 }} />
          Feedback
          <Rating name="size-medium" sx={{ ml: 1 }} onChange={(event, newValue) => {
            if (newValue) {
              saveRating(newValue);
            }
          }}/>
        </Fab>
      </Zoom>




      <Card sx={{ maxWidth: { xs: '100%', sm: '75%' }, maxHeight: { xs: '100%', sm: '85%' }, p: { xs: 0, sm: 5 } }}>
            <CardContent sx={{mx: { xs: .2, sm: 4 ,}}}>
                     
              {/* <Typography variant="h4" color='secondary.main' sx={{ mb: 3, mt: 2, fontSize: {xs: 25,sm : 35} }} component="div">
                <ReportFontStyle>{title}</ReportFontStyle>
              </Typography> */}
              {/* <Accordion variant='outlined' defaultExpanded>
                <AccordionSummary
                  expandIcon={<ArrowDropDownCircleOutlined />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{px:1}}>Given Requirement</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{px:1, pb:1, fontSize: { xs: 13, sm: 18 }} }>
                    <ReportFontStyle>
                      {requirement}
                    </ReportFontStyle>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
              <Box sx={{ mx: { xs: .2, sm: 2 }, fontSize: { xs: 13, sm: 20 }}}>
                <ReportFontStyle><div dangerouslySetInnerHTML={createMarkup(data)} /></ReportFontStyle>
              </Box>
            </CardContent>
          </Card> 

      {/* <Markdown rehypePlugins={[rehypeRaw, remarkGfm]}>{data}</Markdown> */}
      </Container>
      </Box>
    </ThemeProvider>
  );
}
import * as React from 'react';
import { Link, PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from '../components/ToggleColorMode';
import { ABOUT_SLUG, DASHBOARD_URL, FEATURED_URL, UI_BASE_URL, UI_LOGIN_URL } from '../constants/CommonConstants';
import LoginPage from '../components/dashboard/user/LoginPage';
import LogoComponent from '../components/common/LogoComponent';
import { FeedbackTwoTone, LoginOutlined, LoginTwoTone, QuestionAnswerTwoTone, SpaceDashboardOutlined } from '@mui/icons-material';
import FeedbackQuery from '../components/dashboard/user/FeedbackQuery';
import { analyticsEvent } from '../utils/CommonApiCalls';
import { ANA_FEEDBACK_QUERY_OPENED } from '../constants/AnalyticsConstants';
import { isLoggedIn, loginCheck } from '../components/dashboard/user/LoginLogout';
import { RButton } from '../components/common/style/RButton';
import FeedbackComponent from '../utils/FeedbackComponent';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function LandingPageAppBar({ mode, toggleColorMode }: AppAppBarProps) {
  const [open, setOpen] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  function handleLogin () {
      if (loggedIn) {
        window.location.href = DASHBOARD_URL ;
      } else {
        window.location.href = UI_LOGIN_URL;
      }
  }

  const toggleFeedback = (newOpen: boolean) => () => {
    if (newOpen) {
      analyticsEvent(ANA_FEEDBACK_QUERY_OPENED);
    }
    setFeedbackOpen(newOpen);
  };

  React.useEffect(() => {
    async function checkLogin() {
      const data = await isLoggedIn();
      setLoggedIn(data);
    }
    checkLogin();
  }, []);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 0,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '9px',
              bgcolor:
                theme.palette.mode === 'light'
                ? 'rgba(155, 155, 155, 0.2)'
                : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
            })}
          >
            <LogoComponent />
            <Box
              sx={{
                gap: 0.5,
                alignItems: 'center',
              }}
            >

            {/* <Link
                color="secondary"
                // sx={{ display: 'none' }}
                             
              >
               Featured Reports
            </Link> */}

            <Link href={UI_BASE_URL+FEATURED_URL}>
              <RButton
                  variant="outlined"
                  color= {mode === 'dark' ? "secondary" : 'primary'}
                  // aria-label="menu"
                  sx={{ m:1, py: '5px', px:'1', fontSize: '0.975rem', }}
                >
                <Box sx={{display: {xs:"none", sm:"flex"}}}>
                  Featured Reports
                </Box>
                <Box sx={{display: {xs:"flex", sm:"none"}}}>
                  Featured
                </Box>
              </RButton>
            </Link>

            {/* <Link href={UI_BASE_URL+ABOUT_SLUG}>
              <RButton
                  variant="outlined"
                  color= {mode === 'dark' ? "secondary" : 'primary'}
                  aria-label="menu"
                  sx={{ m:1, py: '5px', px:'1', fontSize: { xs: '8', sm: '15' }, }}
                >
                <Box>
                  About
                </Box>
              </RButton>
            </Link> */}
            
            </Box>
            <Box
              sx={{
                mr:0,
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            </Box>
            

            {/* <Box
              sx={{
                mr:1,
                mt:.3,
                gap: 0.5,
                alignItems: 'center',
              }}            
            >
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleFeedback(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <QuestionAnswerTwoTone />
              </Button>
              <Drawer anchor="right" open={feedbackOpen} onClose={toggleFeedback(false)}>
                <Box
                  sx={{
                    minWidth: {xs:"80dvw", sm:'32dvw'},
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                    p: 0
                  }}
                >
                <FeedbackQuery />
                </Box>
              </Drawer>
            </Box> */}


          {loggedIn ? 
          <Box
          sx={{
            mb:.3,
            gap: 0.5,
            alignItems: 'center',
          }}
          >
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={handleLogin}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <SpaceDashboardOutlined />
            </Button>
          </Box>
          : 
          <Box
            sx={{
              mb:.3,
              gap: 0.5,
              alignItems: 'center',
            }}
            >
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <LoginTwoTone />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: {xs:"80dvw", sm:'32dvw'}, 
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                    px: 5,
                    py: 2
                  }}
                >
                  <LoginPage/>
                </Box>
              </Drawer>
            </Box>
            }
          </Toolbar>
        </Container>
      </AppBar>
      <FeedbackComponent defaultQuery />
    </div>
  );
}

export default LandingPageAppBar;
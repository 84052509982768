import { Button, ButtonBaseOwnProps, ButtonOwnProps, FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextField, TextFieldVariants } from '@mui/material'
import { CommonProps } from '@mui/material/OverridableComponent'
import React from 'react'
/**
 * Extend properties
 */

/**
 * Password input
 * @param props Properties
 */
export const RButton = (props : ButtonOwnProps) => {
//    props = Object.assign({ textTransform: 'none' }, props)
    return (
        <Button
            sx={{textTransform: "none", fontSize: '0.975rem'}}
            {...props}>
                {props.children}
        </Button>
    )
}
export function capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

export function isEmptyString(str?: string) {
    if (!str) {
        return false;
    }
    if (str) {
        if (str === null || str.length === 0) {
            return true;
        }
    }
    return false;
}
import * as React from 'react';
import { Alert, Button, PaletteMode, Snackbar, TextField, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from '../../../getLPTheme';
import { useCookies } from 'react-cookie';
import UserData from '../../common/models/UserData';
import { API_USER_LOGIN, DASHBOARD_URL, EARLY_ACCESS_REG } from '../../../constants/CommonConstants';
import { api } from '../../../utils/Axios';
import { analyticsLog } from '../../../utils/CommonApiCalls';
import { ANA_EARLY_ACCESS_EMAIL_SUBMITTED, ANA_USER_LOGGED_IN } from '../../../constants/AnalyticsConstants';
import { getPreferredScheme } from '../../../utils/CommonFunctions';

export default function LoginPage() {
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [cookies, setCookie] = useCookies(['user']);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
    }
  };

  React.useEffect(() => {
    handleLoadUser();
  }, []); 

  function handleClose () {
    setOpen(false);
  }

  function handleLogin () {
    api.post( API_USER_LOGIN, "username="+email+"&password="+password)
    .then(function (response) {
      if (response.status == 200) {
        analyticsLog(ANA_USER_LOGGED_IN, email);
        window.location.href = DASHBOARD_URL ;
      } else {
        setMessage("Login failed, please check your credentials and retry")
        setOpen(true)
      }
    })
    .catch(function (error) {
      setMessage("Login failed, please check your credentials and retry")
      setOpen(true)
    });
  }

  function handleGetEarlyAccess () {
    const data = {
      email: email
    }
    api.post(EARLY_ACCESS_REG, data)
    .then(function (response) {
      if (response.data == "success") {
        analyticsLog(ANA_EARLY_ACCESS_EMAIL_SUBMITTED, email);
        setMessage("Thanks for sharing your email! We will soon get in-touch")
        setOpen(true);
        setEmail("");
      } else {
        setMessage(response.data)
        setOpen(true)
      }
    })
    .catch(function (error) {
      setMessage("We are experiencing some issue at the moment, Please try again later.")
      setOpen(true)
    });
  }

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Box
        sx={{
          minWidth: '35dvw',
          maxWidth: '35dvw',
          flexGrow: 1,
          p: 2,
          pt: 4,
          mx: 'auto',
          my: 0
        }}
      >
        <Snackbar open={open} onClose={handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box>
        {/* <Stack
            direction={{ xs: 'column', sm: 'column' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 1, minWidth: { xs: '100%', sm: '60%' } }}
          > */}
          <Box sx={{border: '1px solid',}}>
          <Typography margin={'5%'} sx={{ fontWeight: 'bold'}}>
          Sign into your account
        </Typography>
        <Box margin={'5%'}>
          <TextField size="small" label="Your email address" fullWidth id='username' onBlur={(event) => setEmail(event.target.value)}/>
        </Box>
        <Box margin={'5%'}>
          <TextField size="small" label="Password" type='password' id='password' fullWidth onBlur={(event) => setPassword(event.target.value)}/>
        </Box>
        <Box margin={'5%'}>
         <Button fullWidth variant="contained" color='secondary' sx={{minWidth : { xs: '100%', sm: '50%'}}}
          onClick={() => { handleLogin() }}
          >
          Sign in
        </Button>
        </Box>

        </Box>
        <Box sx={{border: '1px solid', mt: '12%' }}>
        <Typography margin={'5%'} sx={{ fontWeight: 'bold' }}>
            Don't have account yet?
          </Typography>
          <Box margin={'5%'}>
            <TextField
              fullWidth
              id="email"
              hiddenLabel
              type='email'
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              sx={{minWidth: { xs: '100%', sm: '50%' }}}
            />
            </Box>
            <Box margin={'5%'}>
            <Button fullWidth variant="contained" color='primary' sx={{minWidth : { xs: '100%', sm: '50%'} }} onClick={handleGetEarlyAccess}>
              Get Early Access
            </Button>
            </Box>
          {/* </Stack> */}
          </Box>
        </Box>
      </Box>
      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
      </Box>
    </ThemeProvider>
  );
}
import * as React from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Button, Card, CardContent, Container, PaletteMode, Stack, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { ANA_FEATURED_REPORT_READ_PER_, ANA_PAGE_READ_PER_ } from '../constants/AnalyticsConstants';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import { analyticsLog } from './CommonApiCalls';
import { isEmptyString } from './StringUtils';

export default function PageScrollTracker({tracker, log} : {tracker? : string, log? : string}) {
  const [currentScrollPercentage, setCurrentScrollPercentage] = React.useState(0);
  const [maxScrollPercentageReached, setMaxScrollPercentageReached] = React.useState(0);

  async function handleScroll() {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const currentPosition = window.scrollY;

    var scrollPercentage = (currentPosition / (fullHeight - windowHeight)) * 100;
    scrollPercentage = Math.ceil(scrollPercentage);
    if (scrollPercentage > currentScrollPercentage && scrollPercentage % 10 == 0) {
      await setCurrentScrollPercentage(scrollPercentage);      
    }
  };

  React.useEffect(() => {
    if (currentScrollPercentage > maxScrollPercentageReached) {
      setMaxScrollPercentageReached(currentScrollPercentage);      
    }
  }, [currentScrollPercentage]);

  React.useEffect(() => {
    var event;
    if (!tracker || isEmptyString(tracker)) {
        event = ANA_PAGE_READ_PER_;
    } else {
        event = tracker;
    }
    if (maxScrollPercentageReached > 0) {
      analyticsLog(event + maxScrollPercentageReached, log)
    }
  }, [maxScrollPercentageReached]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box/>
  );
}
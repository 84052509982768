import * as React from 'react';
import { Box, PaletteMode, Typography } from "@mui/material";
import { APP_LOGO_FIRST_NAME,APP_LOGO_LAST_NAME, APP_LOGO_SUFFIX, LOGO, UI_BASE_URL, YELLOW_COLOR } from "../../constants/CommonConstants";

function LogoComponent() {

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
            }}
        >
            <img src={LOGO} style={{ marginLeft: 15, marginRight: 5, cursor: "pointer"}}
                width="25" height="25"
                alt="Logo" onClick={() => {window.location.href=UI_BASE_URL}}/>
            <Box sx={{display: {xs:"none", sm:"flex"}}}>
                <Typography onClick={() => {window.location.href=UI_BASE_URL}}
                    fontWeight={'bold'}
                    sx={(theme) => ({
                        cursor: "pointer", ml:.5, fontSize: { xs: '8', sm: '20px' },
                        color:
                        theme.palette.mode === 'light'
                        ? 'primary.main'
                        : YELLOW_COLOR,
                    })}>
                        {APP_LOGO_FIRST_NAME}
                </Typography>
                <Typography sx={{cursor: "pointer", fontSize: { xs: '8', sm: '20px' }}} onClick={() => {window.location.href=UI_BASE_URL}} color="primary.main" fontWeight={''} >{APP_LOGO_LAST_NAME}</Typography>
                <Typography sx={{cursor: "pointer", fontSize: { xs: '8', sm: '20px' } }} onClick={() => {window.location.href=UI_BASE_URL}} color="primary.main" fontWeight={''} >{APP_LOGO_SUFFIX}</Typography>
            </Box>            
        </Box>
    );
}

export default LogoComponent;
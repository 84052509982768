import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import { RESEARCH_REPORT_FONT } from '../../../constants/CommonConstants';

export default function ReportFontStyle({
    children,
  }: {
    children: React.ReactNode
  }) {
  return (
    //fontFamily: RESEARCH_REPORT_FONT,
    <Box sx={{ textRendering: "optimizeLegibility", letterSpacing: '-0.003em', lineHeight: 1.8, fontSmooth: "antialiased"}}>
        {children}
    </Box>
  );
}
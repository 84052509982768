import * as React from 'react';
import { Button, Container, FormControlLabel, FormLabel, Grid, PaletteMode, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import DashboardAppBar from './components/dashboard/DashboardAppBar';
import getLPTheme from './getLPTheme';
import Header from './components/dashboard/DashboardHeader';
import { useCookies } from 'react-cookie';
import UserData from './components/common/models/UserData';
import {loginCheck} from './components/dashboard/user/LoginLogout';
import EnhancedTable from './components/dashboard/research/ResearchTableView';
import { getPreferredScheme } from './utils/CommonFunctions';

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [researchDetails, setResearchDetails] = React.useState('');
  const [cookies, setCookie] = useCookies(['user']);
  const [user, setUser] = React.useState<UserData>();

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
      setUser(retrievedUser);
    }
  };

  React.useEffect(() => {
    handleLoadUser();
    loginCheck();
  }, []);

  const toggleColorMode = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      retrievedUser.preferences.darkMode = ( mode === 'light');
      setUser(retrievedUser);
      setCookie('user', JSON.stringify(retrievedUser), {
        path: '/',
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Expires in 15 day
        secure: true, // Only sent over HTTPS for enhanced security (recommended)
        sameSite: 'strict', // Mitigates CSRF attacks (recommended)
      });
    }
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  function changeResearchDetails(data: string) {
    setResearchDetails(data);
  }

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <DashboardAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Header />
      <Container
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          pt: '10%' ,
          width: '100%'
        }}
      >

      <EnhancedTable/>
        
      </Container>
      <Box sx={{ bgcolor: 'background.default', pt:5 }}>
        <Divider />
      </Box>
    </ThemeProvider>
  );
}
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Backdrop, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Snackbar, useMediaQuery } from '@mui/material';
import { DeleteTwoTone, HighlightTwoTone, MoreVertSharp, VisibilityTwoTone } from '@mui/icons-material';
import { API_BASE_URL, API_DELETE_RESEARCH, DASHBOARD_URL, STATUS_COMPLETED, SUCCESS_MESSAGE, UI_VIEW_RESEARCH } from '../../../constants/CommonConstants';
import { api } from '../../../utils/Axios';
import ResearchModel from '../../common/models/ResearchModel';

const StyledMenu = styled((props) => (
  <Menu
    open
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ResearchEditMenu({data}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(false);
  const [rowId] = React.useState(data.id);
  const [status] = React.useState(data.status);
  const [openBackdrop, setBackdrop] = React.useState(false);

  function handleDeleteAlertClose () {
    setDeleteAlertOpen(false);
  }

  function handleDeleteAlertOpen () {
    setDeleteAlertOpen(true);
  }
  
  function handleBackdropClose () {
    setBackdrop(false);
  }

  function handleBackdropOpen () {
    setBackdrop(true);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewResearchResult = () => {
    window.open(DASHBOARD_URL + UI_VIEW_RESEARCH  + "?id="+ rowId, '_blank', 'noopener,noreferrer')
    setAnchorEl(null);
  };

  const handleViewResearchFindings = () => {
    window.open(DASHBOARD_URL + UI_VIEW_RESEARCH  + "?brief=true&id="+ rowId, '_blank', 'noopener,noreferrer')
    setAnchorEl(null);
  };  

  const handleDeleteResearch = () => {
    handleBackdropOpen();
    api.get(API_BASE_URL + API_DELETE_RESEARCH + rowId)
    .then(function (response) {
      if (response.status == 200 && response.data == SUCCESS_MESSAGE) {
          setTimeout(function(){
            window.location.reload();
          }, 1000);
      } else {
        console.log("Failed to delete Researches")
      }
      handleBackdropClose();
    })
    .catch(function (error) {
        handleBackdropClose();
        console.log("Failed to delete Researches")
    });
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button> */}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertSharp />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        
      >
        {status == STATUS_COMPLETED ? 
        <Box>
          <MenuItem onClick={handleViewResearchFindings} >
            <HighlightTwoTone />
            Research Findings
          </MenuItem>
          <MenuItem onClick={handleViewResearchResult} >
            <VisibilityTwoTone />
            View Full Report
          </MenuItem>
        </Box>
        : ""}
        <MenuItem onClick={handleDeleteAlertOpen}>
          <DeleteTwoTone />
          Delete
        </MenuItem>
        <Dialog
          open={deleteAlertOpen}
          onClose={handleDeleteAlertClose}
          aria-labelledby="responsive-dialog-title"
        >
          {/* <DialogTitle id="responsive-dialog-title">
            {"Are you sure you want to delete Research?"}
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText>
            Are you sure you want to delete Research?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDeleteAlertClose}>
              Cancel
            </Button>
            <Button onClick={handleDeleteResearch} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}

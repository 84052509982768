import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import  { API_BASE_URL, APP_NAME, EARLY_ACCESS_REG, FEATURED_URL, FEATURED_VIEW_URL, UI_BASE_URL } from "../constants/CommonConstants";
import { Alert, alpha, Backdrop, CircularProgress, colors, Link, Snackbar } from '@mui/material';
import { api } from '../utils/Axios';
import { analyticsLog } from '../utils/CommonApiCalls';
import { ANA_EARLY_ACCESS_EMAIL_SUBMITTED, ANA_FEATURED_REPORT_OPENED } from '../constants/AnalyticsConstants';
import { RTextField } from '../components/common/style/RTextField';
import BasicTable from './landing/ComparisionTable';
import { showcase } from '../components/otherpages/ShowcaseData';

export default function LandingPageBody() {
  const [email, setEmail] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [openBackdrop, setBackdrop] = React.useState(false);

  function handleBackdropClose () {
    setBackdrop(false);
  }

  function handleBackdropOpen () {
    setBackdrop(true);
  }

  function handleClose () {
    setOpen(false);
  }

  const goToShowcase = () => () => {
    window.location.href = FEATURED_URL;
  };
  
  function handleGetEarlyAccess () {
    const data = {
      email: email
    }
    handleBackdropOpen();
    api.post(EARLY_ACCESS_REG, data)
    .then(function (response) {
      if (response.data == "success") {
        analyticsLog(ANA_EARLY_ACCESS_EMAIL_SUBMITTED, email);
        setMessage("Thanks for sharing your email! We will soon get in-touch")
        setOpen(true);
        setEmail("");
      } else {
        setMessage(response.data)
        setOpen(true)
      }
      handleBackdropClose();
    })
    .catch(function (error) {
      setMessage("We are experiencing some issue at the moment, Please try again later.")
      setOpen(true)
      handleBackdropClose();
    });
  }

  return (
    <Box>
    <Box
      sx={(theme) => ({
        width: '100%',
        background:
          theme.palette.mode === 'light'
            // ? 'primary.light' // 
            ? 'linear-gradient(180deg, #e3e6ec, #eee)'
            // : 'grey.light', 
            : 'linear-gradient(180deg, #121820, #0d1117)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
      >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={open} onClose={handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: '15%', sm: '8%' } ,
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="subtitle1"
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              // alignSelf: 'center',
              // textAlign: 'center',
              // fontSize: 'clamp(2rem, 9vw, 5rem)',     
              color: (theme) =>
                theme.palette.primary.main,
            }}
          >
            {/* Swap Research Hours for &nbsp; */}
            How's ResearchStack different from Chatgpt / Perplexity? 
            {/* Meaningful Information */}
            {/* Information That Truly Matters */}
            {/* From Clutter */}
            </Typography>
            {/* <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                // fontSize: 'clamp(3rem, 10vw, 4rem)',
                // fontWeight: '400',
                // color: (theme) =>
                //   theme.palette.mode === 'light' ? 'secondary.main' : 'secondary.light',
              }
            }
            >
              Below comparison should help you understand the difference
            </Typography> */}
            <BasicTable/>
          {/* <Typography>Is research a time-consuming black hole?  You're not alone.</Typography> */}
          <Typography
            variant='subtitle2'
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '90%'}, mb: 3, mt: 5 }}
          >
            ResearchStack is designed to handle research demands that go beyond surface-level insights to provide the well researched comprehensive information. 
          </Typography>
          <Typography
            variant='subtitle1'
            // color="text.secondary"
            sx={{ width: { sm: '100%', md: '82%'}, 
            color: (theme) =>
              theme.palette.primary.main,
          }}
          >
           Checkout sample reports like:
          </Typography>
          {/* <Typography
              variant='subtitle2'
              // color="text.secondary"
              sx={{letterSpacing: '0.03em'}}
            > */}
            <Link sx={{textDecoration: "none"}} variant='subtitle2' href={(UI_BASE_URL+FEATURED_VIEW_URL+"?title="+showcase[0].slug)}
              color="text.primary"
            >
                  {showcase[0].title}
            </Link>
          {/* </Typography>   */}
          {/* <Typography
              variant='subtitle2'
              color="secondary.main"
              sx={{letterSpacing: '0.03em'}}
            > */}
            <Link sx={{textDecoration: "none"}} variant='subtitle2' href={(UI_BASE_URL+FEATURED_VIEW_URL+"?title="+showcase[1].slug)}
              color="text.primary"
            >
              {showcase[1].title}
            </Link>
          {/* </Typography>   */}
          {/* <Typography
              variant='body1'
              color="primary.main"
              sx={{letterSpacing: '0.03em'}}
            > */}
            <Link sx={{textDecoration: "none"}} variant='body1' href={(UI_BASE_URL+FEATURED_URL)}
              color="text.primary"
            >
                and more...
            </Link>
          {/* </Typography>   */}

        </Stack>
        
      </Container>
    </Box>
    <Box
      sx={(theme) => ({
        width: '100%',
        background:
          theme.palette.mode === 'light'
            // ? 'primary.light' // 
            ? 'linear-gradient(0deg, #e3e6ec, #eee)'
            // : 'grey.light', 
            : 'linear-gradient(0deg, #121820, #0d1117)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
      >
      
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: '15%', sm: '8%' } ,
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
        
            
          {/* <Typography>Is research a time-consuming black hole?  You're not alone.</Typography> */}
          {/* <Typography
            variant='body1'
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { marginTop: 15 } }}
          >
            As this comparison demonstrates ResearchStack is designed to handle research demands that go beyond surface-level insights to provide the comprehensive data you need.
          </Typography> */}
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { marginTop: 15 } }}
            variant='subtitle1'
          >

           What to see it in Action?
          </Typography>

          
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 1, minWidth: { xs: '100%', sm: '60%' } }}
          >
            <RTextField
              id="email"
              // hiddenLabel
              type='email'
              size="small"
              // variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              sx={{minWidth: { xs: '100%', sm: '50%' }}}
            />
            <Button variant="contained" color='secondary' sx={{minWidth : { xs: '100%', sm: '50%'} ,fontSize: '0.975rem' , backgroundImage: "linear-gradient(to right top, #f5af2f, #eabe19, #dacd00, #c5dc00, #a8eb12);"}} onClick={handleGetEarlyAccess}>
              Get Early Access
            </Button>
          </Stack>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, pb:10 }}>
          Join the community of early adopters.
          </Typography>
        </Stack>
        
      </Container>
    </Box>
    </Box>
  );
}
import * as React from 'react';
import { Alert, Button, FormControlLabel, MenuItem, PaletteMode, Radio, RadioGroup, Rating, Snackbar, TextField, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from '../../../getLPTheme';
import { useCookies } from 'react-cookie';
import UserData from '../../common/models/UserData';
import axios from 'axios';
import { API_COMMUNICATE_SAY, COMMUNICATE_FEEDBACK, COMMUNICATE_QUERY, DASHBOARD_URL, SUCCESS_MESSAGE, UI_BASE_URL } from '../../../constants/CommonConstants';
import { api } from '../../../utils/Axios';
import { capitalizeFirstLetter, isEmptyString } from '../../../utils/StringUtils';
import { API_SUBMIT_ERROR_TRY_AGAIN } from '../../../constants/CommonMessages';
import { analyticsLog } from '../../../utils/CommonApiCalls';
import { ANA_FEEDBACK_QUERY_SUBMITTED } from '../../../constants/AnalyticsConstants';
import { isLoggedIn } from './LoginLogout';
import { RTextField } from '../../common/style/RTextField';
import { getPreferredScheme } from '../../../utils/CommonFunctions';

export default function FeedbackQuery({noEmail, defaultQuery}: {noEmail?:boolean, defaultQuery?:boolean}) {
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [cookies, setCookie] = useCookies(['user']);
  const [email, setEmail] = React.useState('');
  const [content, setContent] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(COMMUNICATE_FEEDBACK);
  const [message, setMessage] = React.useState('');
  const [loggedIn, setLoggedIn] = React.useState(false);

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
    }
  };

  React.useEffect(() => {
    handleLoadUser();
    setType(defaultQuery ? COMMUNICATE_QUERY : COMMUNICATE_FEEDBACK);
  }, []); // Load user data on component mount

  function handleClose () {
    setOpen(false);
  }

  function handleSendMessage () {
    const data = {
      email: email,
      content: content,
      type: type,
      url: window.location.href
    }

    if (type === COMMUNICATE_QUERY && isEmptyString(email)) {
      setMessage("Please provide a valid email id");
      setOpen(true)
      return;
    }
    if (isEmptyString(content)) {
      setMessage("Please add message");
      setOpen(true)
      return;
    }
    api.post( API_COMMUNICATE_SAY, data)
    .then(function (response) {
      if (response.status == 200) {
        if (response.data === SUCCESS_MESSAGE) {
          if (type === COMMUNICATE_FEEDBACK) {
            setMessage("We appreciate you taking the time to share your thoughts.")        
            setOpen(true)
          } else {
            setMessage("Thanks for reaching out! Your message is important to us. We'll respond as quickly as possible.")        
            setOpen(true)
          }
          setEmail("")
          setContent("")

        } else if (! isEmptyString(response.data)){
          setMessage(response.data);
          setOpen(true)
        } else {
          setMessage("Looks like there was an issue in submitting the "+type);
          setOpen(true)
        }
      } else {
        setMessage(API_SUBMIT_ERROR_TRY_AGAIN)
        setOpen(true)
      }
    })
    .catch(function (error) {
      setMessage(API_SUBMIT_ERROR_TRY_AGAIN)
      setOpen(true)
    });
    analyticsLog(ANA_FEEDBACK_QUERY_SUBMITTED, type);
  }

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Box
        sx={{
          minWidth: '35dvw',
          maxWidth: '80dvw',
          flexGrow: 1,
          // mx: '0dvw',
          // my: '3dvw'
          p: 3
        }}
      >
        <Snackbar open={open} onClose={handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
        <Typography margin={'5%'} >
          Please share your valuable Feedback or Let us know if you have any Queries
        </Typography>
        <Box margin={'5%'}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={defaultQuery ? COMMUNICATE_QUERY : COMMUNICATE_FEEDBACK}
        >
          <FormControlLabel onClick={() => setType(COMMUNICATE_FEEDBACK)} value={COMMUNICATE_FEEDBACK} control={<Radio />} label={capitalizeFirstLetter(COMMUNICATE_FEEDBACK)} />
          <FormControlLabel onClick={() => setType(COMMUNICATE_QUERY)} value={COMMUNICATE_QUERY} control={<Radio />} label={capitalizeFirstLetter(COMMUNICATE_QUERY)} />
        </RadioGroup>
        </Box>
        <Box margin={'5%'}>
          {noEmail? "" : <RTextField size="small" value={email} label={type === COMMUNICATE_QUERY ? "Email" : "Email (Optional)"} required={type === COMMUNICATE_QUERY} fullWidth id='email' onChange={(event) => setEmail(event.target.value)}/>}
          {/* {rating && type === COMMUNICATE_FEEDBACK ? <Rating name="size-medium" defaultValue={2} /> : ""} */}
        </Box>
        <Box margin={'5%'}>
        <RTextField
            size="small"
            label="Your Message"
            id="content"
            multiline
            fullWidth={true}
            value={content}
            onChange={(event) => setContent(event.target.value)}
            rows={8}
          />
        </Box>  
        <Box margin={'5%'}>    
        {/* <Button
          color="primary"
          variant="contained"
          component="a"
          onClick={() => { handleSendMessage() }}
          target="_blank"
          sx={{ width: '90%', my:1, ml:3, minHeight: 40 }}
        > */}
          <Button fullWidth variant="contained" color='primary' sx={{minWidth : { xs: '100%', sm: '50%'} }} onClick={() => { handleSendMessage() }}>
          Send 
        </Button>
        </Box>
      </Box>
      <Box sx={{ bgcolor: 'background.default', mt:2 }}>
        <Divider />
      </Box>
    </ThemeProvider>
  );
}
import { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextField, TextFieldVariants } from '@mui/material'
import React from 'react'
/**
 * Extend properties
 */
export interface TextProps extends OutlinedTextFieldProps {
    // custom: string
}

/**
 * Password input
 * @param props Properties
 */
export const RTextField = (props: React.JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => {
    // props = Object.assign({ variant: 'outlined' }, props)
    return (
        <TextField
            inputProps={{style: {fontSize: 16}}} // font size of input text
            InputLabelProps={{style: {fontSize: 16}}} // font size of input label
            variant="outlined"
            {...props}>
                {props.children}
        </TextField>
    )
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Alert, createTheme, CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import getLPTheme from '../../getLPTheme';
import { getPreferredScheme } from '../../utils/CommonFunctions';

export default function NoMatch() {
    const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
    const LPtheme = createTheme(getLPTheme(mode));
  
    return (
        <ThemeProvider theme={LPtheme}>
        <CssBaseline />
            <Box
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                theme.palette.mode === 'light'
                    ? 'primary.main' // 'linear-gradient(180deg, #CEE5FD, #FFF)'
                    : 'grey.main', //`linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
            >
            <Container
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pt: '10%' ,
                pb: '2%',
                }}>
                <Typography variant="caption" textAlign="center" sx={{ fontSize:30 }}>
                    Page you are looking for doesn't exist.
                </Typography>        
            </Container>
            </Box>
    </ThemeProvider>
  );
}
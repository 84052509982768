import * as React from 'react';
import { Alert, Backdrop, Button, Card, CardContent, CircularProgress, Container, Drawer, Fab, MenuItem, PaletteMode, Rating, Snackbar, TextField, Typography, Zoom } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { QuestionAnswerTwoTone } from '@mui/icons-material';
import getLPTheme from '../getLPTheme';
import UserData from '../components/common/models/UserData';
import { analyticsLog } from './CommonApiCalls';
import { ANA_FEEDBACK_QUERY_OPENED } from '../constants/AnalyticsConstants';
import FeedbackQuery from '../components/dashboard/user/FeedbackQuery';
import { getPreferredScheme } from './CommonFunctions';

export default function FeedbackComponent({defaultQuery, noLabel} : {defaultQuery? : boolean, noLabel?: boolean}) {
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [cookies, setCookie] = useCookies(['user']);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [currentScrollPercentage, setCurrentScrollPercentage] = React.useState(0);
  const [maxScrollPercentageReached, setMaxScrollPercentageReached] = React.useState(0);

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
    }
  };

  React.useEffect(() => {
    handleLoadUser();
  }, []); // Load user data on component mount


  React.useEffect(() => {
    if (currentScrollPercentage > maxScrollPercentageReached) {
      setMaxScrollPercentageReached(currentScrollPercentage);      
    }
  }, [currentScrollPercentage]);

const toggleFeedback = (newOpen: boolean) => () => {
  if (newOpen) {
    analyticsLog(ANA_FEEDBACK_QUERY_OPENED);
  }
  setFeedbackOpen(newOpen);
};

  return (
    <Box>
      <Drawer anchor="right" open={feedbackOpen} onClose={toggleFeedback(false)}>
          <Box
            sx={{
            minWidth: {xs:"80dvw", sm:'32dvw'},
            backgroundColor: 'background.paper',
            flexGrow: 1,
            p: 0
          }}
          >
          <FeedbackQuery defaultQuery={defaultQuery} />
        </Box>
      </Drawer>

      <Zoom in={true} style={{ transitionDelay: '500ms' }}>
        <Fab onClick={toggleFeedback(true)} variant="extended" 
          sx={{ flex: 1, bottom: { position: 'fixed', bottom: 20, right:20, color: mode === "dark" ? "black": "#eee" }}} 
          color="primary"
          aria-label="feedback">
          <QuestionAnswerTwoTone />
          {noLabel ? "" :  <Typography variant='h6' sx={{fontSize: '0.975rem', ml: 1}}> {defaultQuery ? "Query? " : "Feedback"}</Typography>}
        </Fab>
      </Zoom>
    </Box>
  );
}